@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';
@import '@gimlite/watermelon/sass/utils/placeholder.scss';

.confirmForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.confirmCaseSelected,
.confirmCase:hover {
  background-color: var(--color-primary-alpha-10);
}

.confirmCase {
  padding: calc($space * 3);
  border-radius: 5px;
  @extend %card;
  margin: calc($space * 2);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: size(650px);
  display: block;
}

.confirmWrite {
  max-width: 30vw;
  line-height: 1;
}

.confirmForReason {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .confirmForReasonContent {
    margin-top: $space;

    .confirmForReasonContentList {
      margin-top: calc($space / 2);
      padding-left: calc($space * 2);

      li {
        list-style-type: disc !important;
      }
    }
  }
}

@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.vao {
  .vaoPlate {
    margin-left: 10px;
  }

  .vaoPlateDetails {
    padding: size(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    margin-left: 5px;
  }

  .vaoVerbalise {
    z-index: 10;
    position: absolute;
    top: calc($space * 2);
    right: calc($space * 2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vaoBadge {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
  }

  .vaoFilterButton {
    margin-left: 10px;
    margin-bottom: 2px;
  }

  .vaoNotSelectedSession {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.horodatorInfo {
  .horodatorInfoTitle {
    font-weight: bold;
    color: var(--color-primary);
    font-size: size(14px);
  }

  .horodatorInfoList {
    margin-top: 5px;
    padding: 0px 10px 0px 20px;

    li {
      margin: size(8px) 0;
      list-style-type: disc;
      font-size: size(13px);

      strong {
        font-weight: bold;
      }
    }
  }
}

.descriptionItem {
  display: flex;
  justify-content: center;
  align-items: center;

  .descriptionItemIcon {
    margin-right: 5px;
  }
}

.historyDuration {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .historyDurationIcon {
    margin-right: 10px;
  }
}

.vaoHistory {
  max-height: 28vh;
}

@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__back {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;

    &__mask {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    &__image {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }

  .loginWidget {
    border-radius: 6px;
    z-index: 2;
    width: 545px !important;
    top: 145px !important;
    right: 75px !important;
    height: initial;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
    background-color: var(--color-yin);

    .loginForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 5px;

      .loginFormPart {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
      }

      .loginFormText {
        width: 100%;
      }
    }
  }
}

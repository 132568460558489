@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.durationInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .durationInfoTitle {
    font-weight: bold;
    color: var(--color-primary);
    font-size: size(14px);
  }

  .durationInfoList {
    margin-top: 5px;
    padding: 0px 10px 0px 20px;

    li {
      margin: size(8px) 0;
      list-style-type: disc;
      font-size: size(13px);

      strong {
        font-weight: bold;
      }
    }
  }

  .durationInfoEmpty {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.events {
  .eventsDuration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
  }

  .eventsDurationIcon {
    margin-right: 5px;
  }

  .eventsImageBadge {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
  }
}

@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

.infoCameraBadge {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}

.infoFormItem {
  margin-right: $space;
  margin-bottom: $space;
}

.lpnInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  * {
    white-space: nowrap;
  }

  .lpnInfoTitle {
    font-weight: bold;
    color: var(--color-primary) !important;
    font-size: size(14px);
  }

  .lpnInfoList {
    margin-top: 5px;
    padding: 0px 10px 0px 20px;

    li {
      margin: size(8px) 0;
      list-style-type: disc;
      font-size: size(13px);

      strong {
        font-weight: bold;
      }
    }
  }
}

.infoCameraContain {
  position: relative;
  height: 100%;
  width: 100%;
}

@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';
@import '@gimlite/watermelon/sass/utils/mixin.scss';

$radius: 15px;
$lineTotal: 5;
$lineHeight: calc(((100 / 4) / $lineTotal) * 1vh);

.cover {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content minmax(0, 1fr);
  overflow: hidden;

  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__timeline,
  &__contain {
    display: grid;
    height: 100%;
  }

  &__timeline {
    // justify-items: flex-start;
    padding-bottom: size(5px);
    position: relative;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: size(11px);
      width: 100%;

      span {
        text-align: center;
        width: min-content;
        color: var(--color-text);
      }
    }
  }

  &__contain {
    grid-template-columns: 1fr;
    background-color: var(--color-intermediate);
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar();
    height: calc($lineHeight * $lineTotal);

    &__line {
      display: grid;
      grid-template-rows: 1fr;
      height: 100%;
      width: 100%;
      min-height: $lineHeight;
      position: relative;
      overflow: hidden;

      &__back {
        height: 100%;
        width: 100%;

        &--even {
          background-color: var(--color-layout);
        }

        &--odd {
          background-color: var(--color-background);
        }
      }

      &__range {
        position: absolute;
        max-height: 100%;
        inset: 0 0 0 0;

        &__contain {
          position: absolute;
          height: calc(100% - size(25px));
          width: 100%;
          top: 50%;
          transform: translateY(calc(-50%));
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }

  .progress__contain:hover .progress__contain__duration {
    filter: brightness(1.25);
    transform-origin: center center;
  }

  .progress {
    height: 100%;
    border-radius: $radius;
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &__popover {
      z-index: 2;
      inset: 0 0 0 0;
      width: 100%;
      height: clamp(size(15px), 100%, size(35px));
      min-height: size(15px);
    }

    &__type {
      // position: absolute;
      display: inline-block;
      font-size: size(14px);
      z-index: 2;
      top: -20px;
      text-align: center;
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-bottom: 3px;

      span {
        color: var(--color-text);
      }
    }

    &__duration {
      // position: absolute;
      display: inline-block;
      font-size: size(12px);
      z-index: 2;
      bottom: -18px;
      text-align: center;
      display: flex;
      align-items: center;
    }

    &__contain {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: var(--color-border);
      position: relative;
      border-radius: $radius;
      box-shadow: inset 0px 0px 5px var(--color-shadow);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      z-index: 4;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--color-primary-alpha-10);
      }

      &__duration {
        height: 100%;
        border-radius: $radius;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;

        &__item {
          font-size: size(13px);
          color: var(--color-white);
          font-weight: bold;
        }
      }
    }
  }
}
